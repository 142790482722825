import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../auth.js';
import * as apiService from '../../api-service.js';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Preloader from '../../component/Preloader.js';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import SideMenu from '../../component/SideMenu.js';

import { v4 as uuidv4 } from 'uuid';

export default function Valoration() {
  const { t } = useTranslation();
  const { user, loading, signOut, signUp, userAuth0Context, setIsPremium } =
    useAuth();
  const [dataState, setDataState] = useState(undefined);
  const userDataRef = useRef({});
  const navigate = useNavigate();
  const [profiles, setProfiles] = useState(false);
  const [healthy, setHealthy] = useState(false);
  const [index, setIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleCircleButtonClick = async () => {
    if (!loading) {
      try {
        setIsLoading(true);
        window.onbeforeunload = () => {
          return undefined;
        };
        await redirigirACircle();
      } catch (error) {
        console.error('Error en la petición', error);
      } finally {
      }
    }
  };

  const redirigirACircle = async () => {
    const urlCircle =
      'https://sleepzzone.circle.so/join?invitation_token=df308964f68afd4063c775d068bc32537b7926b3-0ab0b72d-586d-4779-91c2-f02f190f0cab';
    window.location.href = urlCircle;
  };

  useEffect(() => {
    (async () => {
      if (!loading) {
        if (user) {
          setDataState('loading');
          const userIdToken = await user.getIdToken();
          const isPremium = true;

          try {
            const { userData, userDoc } = await apiService.getUserData({
              userIdToken,
              userId: user.uid,
            });

            setIsPremium(isPremium);
            const pass = uuidv4();

            if (!userDoc?._createTime) {
              await signUp({
                name: userAuth0Context.name,
                email: userAuth0Context.email,
                password: pass,
                telephone: '',
                disCode: '',
                lastName: '',
                uuid: userAuth0Context?.sub,
              });
            }

            const index = await apiService.getAssesmentIndex(
              userIdToken,
              user.uid
            );

            setIndex(index);
            const healthy = await apiService.getCurrentPittsburg(
              userIdToken,
              user.uid
            );
            setHealthy(healthy);

            userDataRef.current = userData;
            const userProfiles = await apiService.getProfiles(
              userIdToken,
              user.uid
            );

            setProfiles(userProfiles);

            setDataState('success');
          } catch {
            setDataState('error');
          }
        }
      }
    })();
  }, [user, loading]);

  const handleSignOut = async () => {
    await signOut();
    navigate('/');
  };

  const child = loading ? (
    <></>
  ) : user ? (
    dataState === 'loading' ? (
      <Preloader></Preloader>
    ) : dataState === 'error' ? (
      <p>An error occured.</p>
    ) : dataState === 'success' ? (
      <div className="login-page relative">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <nav>
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-4">
              <a href="/" className="flex items-center">
                <img
                  src="/images/logo.webp"
                  className="h-8 mr-3"
                  alt="SleepZzone Logo"
                />
              </a>

              <div className="w-full md:block md:w-auto" id="navbar-default">
                <ul className="font-medium flex justify-between lg:justify-normal items-center lg:p-4 md:p-0 mt-4 md:space-x-8 md:mt-0 md:border-0">
                  <li>
                    <div className="profile-bt-text">
                      <h4 className="text-base font-semibold opacity-60 text-white">
                        {t('ClinicHistory.bienvenido')}{' '}
                        {userDataRef.current.name}
                      </h4>
                      <p className="text-xs text-white premium-text font-medium">
                        {userDataRef.current.premium ? 'Premium' : 'Premium'}
                      </p>
                    </div>
                  </li>
                  <li>
                    <button
                      onClick={handleSignOut}
                      className="rounded-lg inline-block text-white text-base bg-zinc-800 hover:bg-zinc-700 py-2 px-6 font-black"
                    >
                      {t('ClinicHistory.cerrar')}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div class="flex">
            <div className="button-column-profile pt-4 lg:pt-8 pb-12 max-w-2xl">
              <SideMenu />
            </div>
            <div className="divider"></div>
            <div className="pt-4 lg:pt-8 pb-12 max-w-2xl">
              <h3 className="text-white text-2xl pb-4 lg:text-4xl font-black">
                {t('Profile.titulo')}
              </h3>
              <div className="flex flex-col items-center justify-items-center gap-4 rounded-lg px-12 py-12 text-white text-lg gray-btn mt-4 font-semibold lg:font-black ">
                <h3 className="text-white text-lg pb-4 font-black text-center">
                  {t('Profile.formacion')}
                </h3>
                <button
                  formtarget="_blank"
                  class="rounded-lg px-8 py-8 text-white text-lg bg-blue-700 hover:scale-110 hover:bg-sky-400 transition duration-500 font-semibold lg:font-black"
                  onClick={handleCircleButtonClick}
                  disabled={isLoading}
                >
                  Comunidad de Formación de SleepZzone
                </button>
              </div>
              <div className="button-top mt-8 flex gap-8 text-lg justify-between">
                <Link to="/profile">
                  <button class="h-16 rounded-lg text-lg text-white gray-btn hover:scale-110 hover:bg-sky-500 transition duration-500 px-4 font-semibold lg:px-6 lg:font-black">
                    {t('Profile.valoration')}
                  </button>
                </Link>
                <Link to="/PersonalInfo">
                  <button class="h-16 rounded-lg text-lg text-white gray-btn hover:scale-110 hover:bg-sky-500 transition duration-500 px-4 font-semibold lg:px-6 lg:font-black">
                    {t('Profile.info')}
                  </button>
                </Link>
                <Link to="/clinicHistory">
                  <button class="h-16 rounded-lg text-lg text-white gray-btn hover:scale-110 hover:bg-sky-500 transition duration-500 px-4 font-semibold lg:px-6 lg:font-black">
                    {t('Profile.clinic')}
                  </button>
                </Link>
              </div>

              <div></div>
              {index >= 9 && profiles ? (
                <>
                  <p className="profile-list-title">{t('Valoration.text1')} </p>
                  <div className="profile-list">
                    <ul className="profile-list-items">
                      {Object.values(profiles).map((profile, index) => (
                        <li className="profile-list-item" key={index}>
                          <span className="profile-list-bullet">&#9656;</span>
                          <span className="profile-list-name">
                            {profile.name}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <p className="profile-list-title ">
                    {t('Valoration.text2')}{' '}
                  </p>
                  <Link
                    to="/consultations"
                    class="h-16 rounded-lg text-lg text-white bg-blue-700 hover:scale-110 hover:bg-sky-500 transition duration-500 px-4 font-semibold lg:px-6 lg:font-black"
                  >
                    <span>{t('Valoration.boton1')}</span>
                  </Link>
                </>
              ) : index >= 1 && index < 10 && !healthy ? (
                <>
                  <p className="profile-list-title">
                    {t('Valoration.textNotHealthy')}{' '}
                  </p>
                  <div className="profile-list">
                    <div className="profile-list-empty">
                      <p>{t('Valoration.textNotHealthy2')}</p>
                    </div>
                  </div>
                  <div className="flex items-center justify-center mt-8 gap-8">
                    <Link
                      to="/consultations"
                      class="h-16 flex items-center justify-center rounded-lg text-lg text-white bg-blue-700 hover:scale-110 hover:bg-sky-500 transition duration-500 px-4 font-semibold lg:px-6 lg:font-black"
                    >
                      <span>{t('Valoration.boton1')}</span>
                    </Link>
                    <Link
                      to="/questionnair"
                      class="h-16 flex items-center justify-center rounded-lg text-lg text-white bg-blue-700 hover:scale-110 hover:bg-sky-500 transition duration-500 px-4 font-semibold lg:px-6 lg:font-black"
                    >
                      <span>{t('Valoration.botonNotHealthy')}</span>
                    </Link>
                  </div>
                </>
              ) : (index >= 1 && healthy) || index === 10 ? (
                <>
                  <p className="profile-list-title">
                    {t('Valoration.textHealthy')}{' '}
                  </p>
                  <div className="profile-list">
                    <div className="profile-list-empty">
                      <p>{t('Valoration.textHealthy2')}</p>
                    </div>
                  </div>
                  <Link
                    to="/Gallery"
                    class="h-16 flex items-center justify-center rounded-lg text-lg text-white bg-blue-700 hover:scale-110 hover:bg-sky-500 transition duration-500 px-4 font-semibold lg:px-6 lg:font-black"
                  >
                    <span>{t('Valoration.botonHealthy')}</span>
                  </Link>
                </>
              ) : (
                <>
                  <p className="profile-list-title">{t('Valoration.text3')} </p>
                  <div className="profile-list">
                    <div className="profile-list-empty">
                      <p>{t('Valoration.text4')}</p>
                    </div>
                  </div>
                  <Link
                    to="/questionnair"
                    class="h-16 flex items-center justify-center rounded-lg text-lg text-white bg-blue-700 hover:scale-110 hover:bg-sky-500 transition duration-500 px-4 font-semibold lg:px-6 lg:font-black"
                  >
                    <span>{t('Valoration.boton2')}</span>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    ) : undefined
  ) : (
    <div className="login-page relative">
      <div className="wrapper mx-auto px-5 lg:px-0 text-center py-10 lg:py-20">
        <h4 className="text-4xl mb-4">You're not logged in</h4>
        <a
          href="/"
          className="rounded-lg inline-block text-white text-base bg-zinc-800 hover:bg-zinc-700 py-2 px-6 font-black"
        >
          Back to home
        </a>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>
          Perfil - Historial Clínico: Rellena tus datos personales para poder
          saber más de ti y ayudarte a dormir mejor de manera personalizada |
          SleepZzone{' '}
        </title>
        <meta
          name="description"
          content="Con tu pefil completo, podrás conocer más sobre tus hábitos de sueño, consejos para dormir mejor y cómo tus experiencias pueden ayudarte a lograr una noche de sueño más reparadora."
        />
        <meta
          name="keywords"
          content="Dormir, descansar, bienestar, descanso, sueño reparador, consejos para dormir, rendimiento, aprender a dormir"
        />
      </Helmet>
      <div>{child}</div>
    </>
  );
}
