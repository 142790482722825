import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Team() {
  const { t } = useTranslation();
  const teams = [
    {
      img: 'images/personas/Paco.webp ',
      name: 'Francisco Bas',
      title: t('Team.titulo1'),
      prof: t('Team.prof1'),
    },
    {
      img: 'images/personas/Gines.webp ',
      name: 'Dr. Ginés Savater',
      title: t('Team.titulo7'),
      prof: t('Team.prof7'),
    },
    {
      img: 'images/personas/Andy.webp ',
      name: 'Andy Cebollero',
      title: t('Team.titulo8'),
      prof: t('Team.prof8'),
    },
    {
      img: 'images/personas/Oriol.webp ',
      name: 'Dr. Oriol Mercadé',
      title: t('Team.titulo3'),
      prof: t('Team.prof3'),
    },
    {
      img: 'images/personas/Lucas.webp ',
      name: 'Lucas Pelegrin',
      title: t('Team.titulo4'),
      prof: t('Team.prof4'),
    },
    {
      img: 'images/personas/Silvia.webp ',
      name: 'Dra. Silvia Gismera',
      title: t('Team.titulo2'),
      prof: t('Team.prof2'),
    },
    {
      img: 'images/personas/Larrosa.webp ',
      name: 'Dr. Óscar Larrosa',
      title: t('Team.titulo5'),
      prof: t('Team.prof5'),
    },
    {
      img: 'images/personas/Claudio.webp ',
      name: 'Claudio Nieto',
      title: t('Team.titulo6'),
      prof: t('Team.prof6'),
    },
  ];

  return (
    <>
      <section className="Team-sec py-8 lg:py-16">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="Team-header lg:mb-12 text-center">
            <h2 className="font-black text-3xl lg:text-5xl mb-3">
              {t('Team.titulo')}
            </h2>
            <p class="text-base">{t('Team.subtitulo')}</p>
          </div>
          <div className="columns-2 gap-x-6 lg:gap-x-8 space-y-4 lg:space-y-8 lg:columns-4 pt-8 lg:pt-0">
            {teams.map((team, index) => {
              return (
                <div className="team-box mb-8 text-center">
                  <div className="team-img">
                    <img
                      srcSet={team.img}
                      alt="Imagen de miembro del equipo "
                    />
                  </div>
                  <div className="team-details overflow-hidden">
                    <h5 className="text-lg font-extrabold mt-5">{team.name}</h5>
                    <p className="text-sm">{team.title}</p>
                    <p className="text-sm">{team.prof}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="text-center mt-4">
            <a
              href="/knowus"
              className="rounded-lg inline-block text-white mr-6 blue-btn py-3.5 px-6 font-black border-2"
            >
              {t('Team.boton')}
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
