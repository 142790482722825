import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CalendlyModal = (data) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const { index } = data;
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const urlList = [
    "https://calendly.com/consultas-sleepzzone/consulta_inicial",
    "https://calendly.com/consultas-sleepzzone/consulta-medica",
    "https://calendly.com/consultas-sleepzzone/consulta-psicologo",
    "https://calendly.com/consultas-sleepzzone/evaluacion-medica-avanzada",
    "https://calendly.com/consultas-sleepzzone/evaluacion-medico-psicologo",
  ];
  const url = urlList[index];
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      const modalContent = document.querySelector(".modal-content");
      if (isModalOpen && modalContent && !modalContent.contains(event.target)) {
        handleCloseModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);
  return (
    <div className=" mt-5 flex rounded justify-center items-center">
      <button
        className=" flex mb-5 rounded items-center shrink-0 justify-center py-3 px-4 lg:py-3.5 lg:px-6 font-black text-white blue-btn"
        onClick={handleOpenModal}
        data-testid="openModalButton"
      >
        {t("Consultations.pedir")}
      </button>
      {isModalOpen && (
        <div className="modalCalendly">
          <div
            data-testid="ModalContainer"
            className="modal-content w-90 h-5/6"
          >
            <span
              className="flex items-center rounded-xl sticky top-4 left-4 font-semibold justify-center h-10 w-10 close text-white blue-btn mb-2"
              onClick={handleCloseModal}
            >
              &times;
            </span>
            <div
              data-testid="calendlyContainer"
              title="consultanos"
              className="flex rounded-xl justify-center w-full h-full"
            >
              <iframe
                data-testid="calendlyIframe"
                src={url}
                title="Calendly"
                style={{
                  borderRadius: "5px",
                  width: "100%",
                  height: "100%",
                }}
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendlyModal;
